import React, {useState} from "react";
import cookie from "react-cookie";

import { Logo } from "../Logo";
import queryString from "query-string";
import { useStore } from "../../context/state";
import { useRouter } from "../../hooks/useRouter";
import Link from "next/link";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import Whatshot from "@material-ui/icons/Whatshot";
import Home from "@material-ui/icons/Home";
import LocalShipping from "@material-ui/icons/LocalShipping";
import QuestionAnswer from "@material-ui/icons/QuestionAnswer";
import Person from "@material-ui/icons/Person";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
import ExitToApp from "@material-ui/icons/ExitToApp";
import PhoneEnabled from "@material-ui/icons/PhoneEnabled";
import RssFeed from "@material-ui/icons/RssFeed";
import EventNote from "@material-ui/icons/EventNote";
import InsertPhoto from "@material-ui/icons/InsertPhoto";
import Collapse from "@material-ui/core/Collapse";
import ShoppingBasket from "@material-ui/icons/ShoppingBasket";
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import Event from "@material-ui/icons/Event";
import Info from "@material-ui/icons/Info";
import LocationOn from "@material-ui/icons/LocationOn";

import {
    Menu,
    MenuItem,
    MenuButton,
    useMenuState,
    ControlledMenu,
    MenuDivider,
    MenuHeader
} from "@szhsin/react-menu";

export const Main = (props) => {
  const { url, deliveryList, storeList, goToPage, isLogin, onClose } = props;
  const location = useRouter();
  const params = queryString.parse(location.search);
  const [open, setOpen] = useState(false);
  const { dispatch } = useStore();

  const onOpen = (key) => {
      if (open === key) {
          setOpen(false);
      } else {
          setOpen(key);
      }
  };

  const isSelected = (path, strict) =>
    strict ? url === path : (url || "").includes(path);
  return (
    <List className="u-width240 u-padding0">
      <div className="app-header-main u-flexCenter u-justifyContentCenter u-paddingHorizontal24 u-paddingTop10 u-paddingBottom10 u-marginBottom20 u-borderBottom1 u-borderColorLighter">
        <Logo dispatch={dispatch} />
      </div>

      {isLogin ? (
        <ListItem
          className="u-paddingHorizontal24"
          selected={isSelected("account", true)}
          onClick={() => goToPage("/account")}
        >
          <ListItemIcon>
            <Person />
          </ListItemIcon>
          <ListItemText primary="ACCOUNT" />
        </ListItem>
      ) : null}

      {isLogin ? <Divider className="" /> : null}

      <MenuItemLink onClick={() => onOpen("shop")}
                    open={open === "shop"}
                    icon={<ShoppingBasket/>}
                    onClose={onClose}
                    name="Order"
      />
      <Collapse in={open === "shop"} className="u-backgroundGrayLighter" timeout="auto" unmountOnExit>
          <Divider/>
          <List component="div" disablePadding>
              {
                  deliveryList.map((item, i) => {
                    let link = "/delivery/" + (item.slug || item["ecommerce_store_delivery_option_id"])
                    if (item.name && item.name.toLowerCase().includes("catering")) {
                      link = "/catering";
                    }
                      return (
                          <MenuItemLink link={link}
                                          name={item.name}
                                          level={1}
                                          onClose={onClose}
                            />
                      );
                  })
              }
              <MenuItemLink link={"/gift-card-order"}
                              name={<span className="u-flexCenter">Gift Card <CardGiftcardIcon fontSize="small" className="u-textColorNormal u-marginLeft5"/></span>}
                              level={1}
                              disabled={true}
                              onClose={onClose}
                />
          </List>
          <Divider/>
      </Collapse>


      <MenuItemLink onClick={() => onOpen("visit")}
                    open={open === "visit"}
                    icon={<Event/>}
                    name="Visit"
      />
  <Collapse in={open === "visit"} className="u-backgroundGrayLighter" timeout="auto" unmountOnExit>
          <Divider/>
          <List component="div" disablePadding>
              {
                  storeList.map((item, i) => {
                      return (
                          <MenuItemLink link={"/stores#" + (item.slug || item["ecommerce_store_delivery_option_pickup_id"])}
                                          name={item.name}
                                          level={1}
                                          onClose={onClose}
                            />
                      );
                  })
              }
          </List>
          <Divider/>
      </Collapse>

      <MenuItemLink icon={<RssFeed/>}
                    link="/blog"
                    name="What's New"
                    onClose={onClose}
      />


      <MenuItemLink onClick={() => onOpen("about")}
                    open={open === "about"}
                    icon={<Info/>}
                    name="About"
      />
      <Collapse in={open === "about"} className="u-backgroundGrayLighter" timeout="auto" unmountOnExit>
          <Divider/>
          <List component="div" disablePadding>
              <MenuItemLink link="/about"
                            name="Our Story"
                            level={1}
                            onClose={onClose}
              />
          <MenuItemLink link="/jobs"
                            name="Work With Us!"
                            level={1}
                            onClose={onClose}
              />
          <span className="u-flex u-paddingBottom5 u-textColorNormal u-paddingTop15 u-marginLeft80 u-paddinVertical12">Help Center</span>
              <MenuItemLink link="/faq"
                            name="FAQ"
                            level={1}
                            onClose={onClose}
              />
          <MenuItemLink link="/policy-center"
                            name="Policy Center"
                            level={1}
                            onClose={onClose}
              />
          <MenuItemLink link="https://piroshkypiroshky.prowly.com/"
                            name="Press | Media Kit"
                            level={1}
                            onClose={onClose}
              />
          </List>
          <Divider/>
      </Collapse>


      <MenuItemLink onClick={() => onOpen("contact")}
                    open={open === "contact"}
                    icon={<LocationOn/>}
                    name="Contact"
      />
  <Collapse in={open === "contact"} className="u-backgroundGrayLighter" timeout="auto" unmountOnExit>
          <Divider/>
          <List component="div" disablePadding>
              <MenuItemLink link="/contacts"
                            name="Contact us"
                            level={1}
                            onClose={onClose}
              />
          <MenuItemLink onClick={() => dispatch(location.pathname + "?"+ queryString.stringify({...params, subscribe_form: true}))}
                            name="Join our Newsletter"
                            level={1}
                            onClose={onClose}
              />
          </List>
          <Divider/>
      </Collapse>


      <div className="u-marginBottom30"></div>

      {isLogin ? (
        <ListItem
          className="u-paddingHorizontal24"
          onClick={() => {
            dispatch("login", { token: undefined });
            dispatch("customer", { delete: {} });
            dispatch("/");
            logout();
            onClose();
          }}
        >
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary="Sign Out" />
        </ListItem>
      ) : null}
      {isLogin ? null : (
        <ListItem
          className="u-paddingHorizontal24"
          onClick={() => goToPage("/login")}
        >
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary="Login" />
        </ListItem>
      )}
      {isLogin ? null : (
        <ListItem
          className="u-paddingHorizontal24"
          onClick={() => goToPage("/signup")}
        >
          <ListItemIcon>
            <AddCircleOutline />
          </ListItemIcon>
          <ListItemText primary="Sign Up" />
        </ListItem>
      )}
    </List>
  );
};

export const logout = (dispatch) => {
  cookie.remove("token", { path: "/" });
  cookie.remove("token_refresh", { path: "/" });
  if (dispatch) {
    dispatch();
  }
};

function MenuLink(props) {
  const activeClass = props.isActive ? " active" : "";
  const item = (
      <span
        className={
          "menu-item u-flexCenter" +
          activeClass +
          " " +
          (props.className || "")
        }
      >
        {props.name}
      </span>
  );
  if (!props.link) {
      return item;
  }
  return (
    <Link href={props.link}>
      <a>
        {item}
      </a>
    </Link>
  );
}

function MenuItemLink(props) {
    const {name, link, open, icon, level, onClose, onClick} = props;
    const component = (
        <ListItem button className={level === 1 ? "u-paddingLeft80 u-paddingRight5" : "u-paddingHorizontal24"} onClick={onClick}>
            {
                icon ?
                    <ListItemIcon>
                      {icon}
                    </ListItemIcon>
                    :
                    null
            }
          <ListItemText className={level > 0 ? "u-margin0" : ""} primary={name} />
          {open ? <ExpandLess /> : (open === false ? <ExpandMore /> : null)}
        </ListItem>
    );
    if (!link) {
        return component;
    }
  return (
    <Link href={link}>
      <a onClick={() => onClose ? onClose() : null}>
        {component}
      </a>
    </Link>
  );
}
