import React from "react";
import queryString from "query-string";
import { useRouter as useRouterBase } from "next/router";


export const useRouter = () => {
    const location = useRouterBase();
    const search = "?" + queryString.stringify(location.query || {});
    return {...location, search};
};
