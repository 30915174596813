import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Apartment from "@material-ui/icons/Apartment";
import queryString from "query-string";


export const calcPrice = (obj, featureList) => {
    let price = parseFloat(obj.price || 0);

    (featureList || []).forEach(attribute => {
        if (attribute.is_priced) {
            (attribute.values || []).forEach(value => {
                if (value.price) {
                    price += value.price;
                }
            });
        }
    });


    let discount = obj.discount_percent ? price * (1 - (parseFloat(obj.discount_percent) / 100)) : (obj.discount ? parseFloat(obj.discount) : 0);
    let discount_percent = obj.discount_percent ? "-" + (obj.discount_percent) : (obj.discount ? Math.round(((discount / price) - 1 ) * 100) : 0);
    let final_price = discount_percent ? discount : price;

    if (final_price < 0 || price < 0 || discount < 0) {
        final_price = 0;
        price = 0;
        discount = 0;
        if (discount_percent) {
            discount_percent = "-100";
        }
    }

    let price_fixed = price.toFixed(2);
    if (price_fixed.endsWith(".00"))  {
        //price_fixed = price_fixed.split(".")[0];
    }
    let discount_fixed = discount ? discount.toFixed(2) : "";
    if (discount_fixed.endsWith(".00"))  {
        //discount_fixed = discount_fixed.split(".")[0];
    }
    let final_price_fixed = final_price ? final_price.toFixed(2) : "";
    if (final_price_fixed.endsWith(".00"))  {
        //final_price_fixed = final_price_fixed.split(".")[0];
    }
    if (price < 0) {
        price_fixed = "Contact our manager for price details";
    }


    return {final_price, final_price_fixed, price, discount, discount_percent, price_fixed, discount_fixed};
};

export const renderFeatureVertical = (items) => {
    return (
        <div>
            {
                (items || []).map((feature, i) => {
                    if (!feature) {
                        return null;
                    }
                    return (
                        <span className="product_property u-marginVertical2">{feature}</span>
                    );
                })
            }
        </div>
    );
};

export const renderNameVertical = (item, options) => {
    options = options || {};
    let name = item.model || item.name;
    if (name.length > 70 && !options.isNameFull) {
        name = name.slice(0, 70) + "...";
    }
    let manufacturer = item.manufacturer || "";
    if (options.isManufacturerFull && manufacturer.length > 20) {
        manufacturer = manufacturer.slice(0, 20) + "..";
    }
    const featureList = options.featureList || [];
    return (
        <div className="product-name-vertical u-flexColumn u-sizeFullWidth">
            {
                options.isNameH1 ?
                    <h1  className={"u-marginBottom4 u-textColorDarker " + (options.nameClassName ? options.nameClassName : "")}>
                        {name}
                    </h1>
                    :
                    <h4  className={"u-marginBottom4 u-textColorDarker " + (options.nameClassName ? options.nameClassName : "")}>
                        {name}
                    </h4>

            }
            {
                !options.noContent ?
                    <div    className="product-name-content u-flexCenter u-flexWrap"
                            style={{color: "#9d9ca5"}}>
                        <div className="product_property u-marginVertical2">
                            {
                                featureList.map((attribute, i) => {
                                    const isPriced = attribute.is_priced;
                                    return (
                                        <div className="u-flexCenter">
                                            {attribute.name || ""}:
                                            {
                                                (attribute.values || []).map((value, i) => {

                                                    return (
                                                        <div className="u-marginLeft5">
                                                            {value.value} {isPriced && value.price ? ("+$" + value.price.toString()) : ""}
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                    :
                    null
            }
        </div>
    );
};

const sizePosition = {
    "XS": 1,
    "S": 2,
    "M": 3,
    "L": 4,
    "XL": 5,
    "XXL": 6,
    "XXXL": 7
};

export const processClothesSize = (size, options) => {
    options = options || {};
    let sizes = ["", "", "", "", "", ""];
    const sizeSplit = size.split(";");
    sizeSplit.sort();
    sizeSplit.forEach((item, i) => {
        const position = sizePosition[item];
        if (position) {
            sizes[position] = item;
        } else {
            sizes.push(item);
        }
    });
    sizes = sizes.filter(v => !!v);
    sizes = sizes.join(" ");
    if (!options.isNameFull && sizes.length > 20) {
        return (
            <Tooltip title={sizes}>
                <div>
                    {sizes.slice(0, 15) + ".."}
                </div>
            </Tooltip>
        );
    }
    return sizes;

};


export const renderNameHorizontal = (item, className) => {
    return (
            <div    className={"u-fontSize10 u-sizeFullWidth " + (className || "")}
                    style={{color: "#9d9ca5"}}>
                {item.model}
                {
                    item.size ?
                        <span className="product_property">{item.size}</span>
                        :
                        null
                }
                {
                    item.how_to ?
                        <span className="product_property">{item.how_to}</span>
                        :
                        null
                }
                {
                    item.for_whom ?
                        <span className="product_property">{item.for_whom}</span>
                        :
                        null
                }
                {
                    item.release_form ?
                        <span className="product_property">{item.release_form}</span>
                        :
                        null
                }
            </div>

    );
};

export const addFeatureList = (features, options) => {
    options = options || {};

    features = features.filter((v, i) => !v.is_hidden).filter((v, i) => !(options.maxOne && i !== 0));
    if (features.length === 0) {
        return null;
    }
    return (
        <React.Fragment>
            {
                features.map((feature, i) => {
                    return (
                        <div className="image-feature"
                             style={{top: 8 + i * 30}}>
                             {feature.name}
                        </div>
                    );
                })
            }
        </React.Fragment>
    );
};

export const addParamToQueryString = (key, value, location, options) => {
    options = options || {};
    const params = queryString.parse(location.search);
    params[key] = value;
    return (options.pathname || location.pathname) + "?" + queryString.stringify(params);
};

export const deleteParamFromQueryString = (key, location, options) => {
    options = options || {};
    const params = queryString.parse(location.search);
    delete params[key];
    return (options.pathname || location.pathname) + "?" + queryString.stringify(params);
};

export const changeParamsInQueryString = (items, location, options) => {
    options = options || {};
    const params = queryString.parse(location.search);
    items.forEach((item, i) => {
        if (item.value) {
            params[item.key] = item.value;
        } else {
            delete params[item.key];
        }
    });

    return (options.pathname || location.pathname) + "?" + queryString.stringify(params);
};


export const getDeliveryUpsaleText = (costJson, amount) => {
    amount = amount || 0
    if (!amount) return null
    try {
        let text = null
        const rules = JSON.parse(costJson || "[]");
        rules.forEach((rule, i) => {
            const isLast = i === (rules.length - 1)
            if (!isLast) {
                if (rule.amount_min <= amount && rule.amount_max >= amount) {
                    const nextRule = rules[i + 1]
                    const nextIsLast = i + 1 === (rules.length - 1)
                    const amountLeft = (nextRule.amount_min || 0) - amount
                    const cost = nextRule.cost ? ("$" + nextRule.cost.toString()) : "free" 
                    if (amountLeft > 0 && !text) {
                        text = <span>add extra <span className="u-fontWeightBold">${amountLeft.toFixed(2)}</span> and reduce handling fee to <span className="u-fontWeightBold u-textColorGreenDark">{cost}</span></span>
                    }
                }
            }
            if (isLast && !text) {
                const cost = rule.cost ? ("$" + rule.cost.toString()) : "free" 
                text = <span>Handling: <span className="u-fontWeightBold u-textColorGreenDark">{cost}</span></span>
            }
        })
        return text
    } catch(e) {
        return null
    }
    return null
}