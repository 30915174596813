import React, {useState} from "react";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

const confirmationText = "DELETE";

export default function DeleteDialog(props) {
    const {action, onClose, withConfirmation } = props;
    const [value, setValue] = useState("");
    const isEqual = confirmationText === value;
    return (
        <div>
            <DialogTitle id="alert-dialog-title">{"Delete Confirmation"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to PERMANENTLY delete this entry?
                </DialogContentText>
            </DialogContent>
            {
                withConfirmation ?
                    <TextField  className="u-marginHorizontal24"
                                variant="outlined"
                                margin="dense"
                                value={value}
                                helperText={<span>Please, enter <span className="u-textColorRed u-fontWeightBold">DELETE</span> to confirm delete action</span>}
                                onChange={(event)=> setValue(event.target.value)}/>
                    :
                    null
            }


            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button disabled={withConfirmation ? !isEqual : undefined} onClick={() => {action(); onClose();}}
                        color="secondary"
                        autoFocus>
                    Delete
                </Button>
            </DialogActions>
        </div>
    );
}
