
export default function CRUD(items, itemId, itemIdValue, item, action, options) {
    options = options || {};
    if (options.oldState) {
        return options.oldState;
    }
    if (options.newState) {
        return options.newState;
    }
    if (!itemIdValue) {
        const isPath = (itemId || "").includes(".");
        itemIdValue = isPath ? get(item, itemId) : item[itemId];
    }
    if (action === "CREATE") {
        items = create(items, item, options.unshift);
    } else if (action === "SET") {

    } else if (action === "GET") {
        return getOne(items, itemId, itemIdValue);
    } else if (action === "UPDATE") {
        items = update(items, itemId, itemIdValue, item, options.merge);
    } else if (action === "UPDATE_PARTIAL") {
        items = updatePartial(items, itemId, itemIdValue, options.key, options.value, options.merge);
    } else if (action === "DELETE") {
        items = deleteObj(items, itemId, itemIdValue);
    } else if (action === "DELETE_PARTIAL") {
        items = deletePartial(items, itemId, itemIdValue, options.key);
    } else if (action === "UPDATE_MANY") {
        items = updateMany(items, itemId, itemIdValue, item, options.merge);
    } else if (action === "UPDATE_MANY_PARTIAL") {
        items = updateManyPartial(items, itemId, itemIdValue, options.keyList, options.valueList, options.merge);
    } else if (action === "UPDATE_PARTIAL_LIST") {
        items = updatePartialList(items, itemId, itemIdValue, options.key, options.itemIdSub, options.itemIdValueSub, options.itemSub, options.action);
    }
    return items;
}

export function CRUDOne(item, action, key, value, options) {
    options = options || {};
    if (options.oldState) {
        return options.oldState;
    }
    if (options.newState) {
        return options.newState;
    }
    if (action === "UPDATE_PARTIAL") {
        if (options.merge) {
            set(item, key, {...(get(item, key) || {}), ...value});
        } else {
            set(item, key, value);
        }
    } else if (action === "UPDATE") {
        if (options.merge) {
            item = {...item, ...value};
        } else {
            item = value;
        }
    } else if (action === "DELETE_PARTIAL") {
        deleteProperty(item, key);
    }
    return item;
}


function create(items, item, unshift) {
    if (unshift) {
        items.unshift(item);
    } else {
        items.push(item);
    }
    return items;
}

function update(items, itemId, itemIdValue, item, merge) {
    const isPath = itemId.includes(".");
    items = items.map((v, i) => {
        const idValue = isPath ? get(v, itemId) : v[itemId];
        if (idValue === itemIdValue) {
            if (merge) {
                return {...v, ...item};
            }
            return item;
        }
        return v;
    });
    return items;
}

function getOne(items, itemId, itemIdValue) {
    const isPath = itemId.includes(".");
    const itemsFilter = items.filter((v, i) => {
        const idValue = isPath ? get(v, itemId) : v[itemId];
        if (idValue === itemIdValue) {
            return true;
        }
        return false;
    });
    if (itemsFilter.length) {
        return itemsFilter[0];
    }
    return {};
}

function updatePartial(items, itemId, itemIdValue, key, value, merge) {
    const isPath = itemId.includes(".");
    items = items.map((v, i) => {
        const idValue = isPath ? get(v, itemId) : v[itemId];
        if (idValue === itemIdValue) {
            if (merge) {
                set(v, key, {...(get(v, key) || {}), ...value});
            } else {
                set(v, key, value);
            }
        }
        return v;
    });
    return items;
}

function updatePartialList(items, itemId, itemIdValue, key, itemIdSub, itemIdValueSub, itemSub, action) {
    const isPath = itemId.includes(".");
    items = items.map((v, i) => {
        const idValue = isPath ? get(v, itemId) : v[itemId];
        if (idValue === itemIdValue) {
            let l = get(v, key);
            l = CRUDList(l, itemIdSub, itemIdValueSub, itemSub, action);
            set(v, key, l);
        }
        return v;
    });
    return items;
}

function deleteObj(items, itemId, itemIdValue) {
    const isPath = itemId.includes(".");
    return items.filter((v, i) => (isPath ? get(v, itemId) : v[itemId]) !== itemIdValue);
}

function deletePartial(items, itemId, itemIdValue, key) {
    const isPath = itemId.includes(".");
    items = items.map((v, i) => {
        const idValue = isPath ? get(v, itemId) : v[itemId];
        if (idValue === itemIdValue) {
            deleteProperty(v, key);
        }
        return v;
    });
    return items;
}

function updateMany(items, itemId, itemIdValueList, itemsNew, merge) {
    itemsNew.forEach((item, i) => {
        items = update(items, itemId, itemIdValueList[i], item, merge);
    });
    return items;
}

function updateManyPartial(items, itemId, itemIdValueList, keyList, valueList, merge) {
    itemIdValueList.forEach((itemIdValue, i) => {
        items = updatePartial(items, itemId, itemIdValue, keyList[i], valueList[i], merge);
    });
    return items;
}


function set(obj, path, value) {
    var schema = obj;  // a moving reference to internal objects within obj
    var pList = path.split(".");
    var len = pList.length;
    for(var i = 0; i < len-1; i++) {
        var elem = pList[i];
        if( !schema[elem] ) schema[elem] = {};
        schema = schema[elem];
    }

    schema[pList[len-1]] = value;
}


function get(obj, key) {
    const keyList = key.split(".");
    return keyList.reduce((xs, x) => (xs && xs[x]) ? xs[x] : null, obj);
}

function deleteProperty (obj, path) {

  if (!obj || !path) {
    return;
  }

  if (typeof path === "string") {
    path = path.split(".");
  }

  for (var i = 0; i < path.length - 1; i++) {

    obj = obj[path[i]];

    if (typeof obj === "undefined") {
      return;
    }
  }

  delete obj[path.pop()];
};
