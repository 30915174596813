import React, {useEffect} from "react";
import Dialog from "@material-ui/core/Dialog";
import DeleteDialog from "./components/DeleteDialog";
import CartResetDialog from "./components/CartResetDialog";
import "./style.styl";


export default function DialogCore(props) {
    let {isOpen, option, type, fullScreen, dispatch} = props;
    option = option || {};

    const onClose = () => {
        dispatch("dialog_core", {isOpen: false, option: {}});
    };

    const renderDialog = () => {
        const props_ = {onClose};

        if (option.component) {
            return React.cloneElement(option.component, props_);
        }

        if (type === "DELETE" || type === "DELETE_WITH_CONFIRMATION") {
            return <DeleteDialog {...props_} {...option || {}} withConfirmation={type === "DELETE_WITH_CONFIRMATION"}/>;
        }

        if (type === "CART_RESET") {
            return <CartResetDialog {...props_} {...option || {}}/>;
        }

        return <div></div>;
    };

    const mobileClass = option.isMobile || !fullScreen ? " mobile" : "";
    return (
        <Dialog
            fullScreen={["ARTICLEEDIT"].includes(type) || option.fullScreen || fullScreen}
            open={isOpen}
            onClose={onClose}
            className={mobileClass}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            {renderDialog()}
        </Dialog>
    );
}



export const dialog_core = {
    isOpen: false,
    type: "DEFAULT",
    option: {}
};



export function DialogFake(props) {

    useEffect(() => {
        document.body.classList.add("nooverflow");
        return document.body.classList.remove("nooverflow");
    }, []);

    return (
        <div className="dialog-fake overlay"
             onClick={() => console.log("helloooo!")}>
        </div>
    );
}
