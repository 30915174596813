import { createContext, useContext, useReducer } from "react";
import { useRouter } from "../hooks/useRouter";

import {
  ecommerceStoreId,
  apiPath,
  mediaPath,
  blogPath,
  ecommerceStoreGroupId,
  blogEcommerceStoreGroupId,
  blogEcommerceStoreId,
  recaptchaKey,
} from "../constants";
import cookie from "react-cookie";
import { sideMenu } from "../components/SideMenu";
import { location_widget } from "../components/LocationWidget";

import { notification } from "commons-ui/core/Notification";
import { dialog_core } from "commons-ui/core/Dialog";
import { cart, cartReducer } from "ecommerce-commons-ui/core/Cart";

export const StoreContext = createContext();
const StoreDispatchContext = createContext();

const token = cookie.load("token");

const defState = {
  style: {
    isSideMenuOpen: false,
    isMobile: undefined,
    height: 0,
    width: 0,
    isScroll100: false,
  },
  device: {},
  store_settings: {},
  customer: {},
  notification,
  sideMenu,
  cart,
  dialog_core,
  login: {
    token: token === "undefined" ? "" : token,
  },
  location_widget,
  meta: {
    ecommerceStoreId,
    ecommerceStoreGroupId,
    apiPath,
    blogPath,
    mediaPath,
    blogEcommerceStoreId,
    blogEcommerceStoreGroupId,
    recaptchaKey
  },
};

const reducers = {
  cart: cartReducer,
};

const mainReducer = (state_, action) => {
  const state = { ...state_ };
  let payload = action.payload;
  let content = action.content || action.type;

  payload = reducers[content]
    ? reducers[content](state[content], payload, action.action, {
        state: { ...state },
      }) || payload
    : payload;

  if ("delete" in payload) {
    state[content] = payload.delete;
  } else {
    const localState = state[content] || {};
    const newLocalState = { ...localState, ...payload };
    state[content] = newLocalState;
  }

  return state;
};

export function StoreWrapper({ children }) {
  const [state, dispatch_] = useReducer(mainReducer, defState);
  const router = useRouter();

  const dispatch = (content, payload, action) => {
    if (!payload) {
      router.push(content, undefined, { scroll: false });
      return;
    }

    dispatch_({ type: content, payload, action });
  };

  const value = { ...state, ...state.style, ...state.meta, dispatch };

  return (
    <StoreContext.Provider value={value}>
      <StoreDispatchContext.Provider value={dispatch}>
        {children}
      </StoreDispatchContext.Provider>
    </StoreContext.Provider>
  );
}

export function useStore() {
  return useContext(StoreContext);
}

export function useDispatch() {
  return useContext(StoreDispatchContext);
}
