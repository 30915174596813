import React, {useState} from "react";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

const confirmationText = "DELETE";

export default function CartResetDialog(props) {
    const {action, onClose} = props;
    return (
        <div>
            <DialogTitle id="alert-dialog-title">{"Cart Reset Confirmation"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    You are trying to add an item from another delivery option. This action will reset your cart.
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => {action(); onClose();}}
                        color="secondary"
                        autoFocus>
                    Continue
                </Button>
            </DialogActions>
        </div>
    );
}
