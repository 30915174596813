import axios from "axios";
import cookie from "react-cookie";


const renderMethod = (method, url, data=undefined, config=undefined) => {
    switch (method) {
        case "GET":
            return axios.get(url, config);
        case "POST":
            return axios.post(url, data, config);
        case "PUT":
            return axios.put(url, data, config);
        case "PATCH":
            return axios.patch(url, data, config);
        case "DELETE":
            return axios.delete(url, config);
        default:
            return axios.get(url, config);
    }
};


export default function dataSource(endpoint, options) {
    const {method, data, config, url, tokenKey, token} = options || {};
    const token_ = token || cookie.load(tokenKey || "token");

    const config_default = {
        headers: { ...(token_ && token_!=="undefined" ? {Authorization: ` Bearer ${token_}`} : {}) }
    };

    const config_option = config || config_default;

    const http_method = method || (data ? "POST" : "GET");

    const url_base = `${url || "http://163.172.178.241:8009"}/${endpoint}`;
    return new Promise((resolve) => {
        resolve(
            (renderMethod(http_method, url_base, data, config_option))
                .then((response) => {
                    const data = response.data;
                    if (data.response){
                        return data.response;
                    } else{
                        return data;
                    }
                })
                .catch(error => {
                    console.log(error);
                    if (!error.response) {
                        return {status: 1};
                    }

                    if (error.response.status === 401){
                        cookie.remove("token", {path: "/"});
                        cookie.remove("username", {path: "/"});
                        cookie.remove("name", {path: "/"});
                        cookie.remove("userId", {path: "/"});
                        cookie.remove("role", {path: "/"});
                        cookie.remove("surname", {path: "/"});
                        window.location.href = "/login";
                    }
                    return {status: error.response.status};
                })
            );
        }
    );
};

const onLogout = () => {
    cookie.remove("token", {path: "/"});
    cookie.remove("token_refresh", {path: "/"});
    window.location.href = "/login";
};

export function dataSourceV2(endpoint, options) {
    const {method, data, auth, config, url, tokenKey, noHeaders} = options || {};
    const token = cookie.load(tokenKey || "token");

    const headers = {};
    if (token && token !== "undefined" && !noHeaders) {
        headers["Authorization"] = " Bearer " + token;
    }

    const config_option = config || {};
    config_option["headers"] = {...(config_option["headers"] || {}), ...headers};
    if (auth) {
        config_option.auth = auth;
    }

    const http_method = method || (data ? "POST" : "GET");

    const url_base = url ? `${url}/${endpoint}` : endpoint;

    return new Promise((resolve) => {
            resolve(
                (renderMethod(http_method, url_base, data, config_option))
                    .then((response) => {
                        const data = response.data;
                        if (data.response){
                            return data.response;
                        } else{
                            return data;
                        }
                    })
                    .catch(error => {
                        //return
                        if (endpoint.includes("token_refresh")) {
                            return onLogout();
                        }
                        console.log(error);
                        if (!error.response) {
                            return {status: 1};
                        }
                        if (error.response.status === 401) {
                            const refresh = cookie.load("token_refresh");
                            if (refresh) {
                                cookie.remove("token", {path: "/"});
                                dataSourceV2("token_refresh", {data: {refresh}, url})
                                    .then(k => {
                                        if (k && k.access) {
                                            cookie.save("token", k.access, {path: "/"});
                                            return dataSourceV2(endpoint, options);
                                        } else {
                                            return onLogout();
                                        }

                                    });
                            } else {
                                return onLogout();
                            }


                        }
                        return {status: error.response.status};
                    })
            );
        }
    );
};
