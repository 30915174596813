import { dataSourceV2 } from "commons-ui/core/DataSource";

const createSortQuery = (sort, ecommerceStoreId, options) => {
  options = options || {};
  if (options.isSearch) {
    return [
      {
        _score: { order: "desc" },
      },
    ];
  }
  const res = [];
  const stock = {
    "search_data.number_dynamic.value": {
      missing: "_first",
      mode: "avg",
      order: "asc",
      nested: {
        path: "search_data.number_dynamic",
        filter: {
          term: {
            "search_data.number_dynamic.name":
              "is_not_in_stock|" + ecommerceStoreId,
          },
        },
      },
    },
  };
  const pupular = {
    "search_data.number_dynamic.value": {
      missing: "_last",
      mode: "avg",
      order: "desc",
      nested: {
        path: "search_data.number_dynamic",
        filter: {
          term: {
            "search_data.number_dynamic.name":
              "position_boost|" + ecommerceStoreId,
          },
        },
      },
    },
  };
  if (!sort || sort === "popular") {
    res.push(stock);
    res.push(pupular);
    res.push({ "search_data.name_sort": { order: "asc" } });
  } else if (sort === "created_at_asc") {
    res.push(stock);
    res.push({
      "search_data.created_at": {
        order: "asc",
      },
    });
  } else if (sort === "created_at_desc") {
    res.push(stock);
    res.push({
      "search_data.created_at": {
        order: "desc",
      },
    });
  } else if (sort === "price_asc") {
    res.push(stock);
    res.push({
      "search_data.number_dynamic.value": {
        missing: "_last",
        mode: "avg",
        order: "asc",
        nested: {
          path: "search_data.number_dynamic",
          filter: {
            term: {
              "search_data.number_dynamic.name": "price|" + ecommerceStoreId,
            },
          },
        },
      },
    });
  } else if (sort === "price_desc") {
    res.push(stock);
    res.push({
      "search_data.number_dynamic.value": {
        missing: "_last",
        mode: "avg",
        order: "desc",
        nested: {
          path: "search_data.number_dynamic",
          filter: {
            term: {
              "search_data.number_dynamic.name": "price|" + ecommerceStoreId,
            },
          },
        },
      },
    });
  } else if (sort === "discount_percent") {
    res.push(stock);
    res.push({
      "search_data.number_dynamic.value": {
        missing: "_last",
        mode: "avg",
        order: "desc",
        nested: {
          path: "search_data.number_dynamic",
          filter: {
            term: {
              "search_data.number_dynamic.name":
                "discount_percent|" + ecommerceStoreId,
            },
          },
        },
      },
    });
    res.push({
      "search_data.number_dynamic.value": {
        missing: "_last",
        mode: "avg",
        order: "asc",
        nested: {
          path: "search_data.number_dynamic",
          filter: {
            term: {
              "search_data.number_dynamic.name": "price|" + ecommerceStoreId,
            },
          },
        },
      },
    });
  } else if (sort === "name") {
    res.push(stock);
    res.push({ "search_data.name_sort": { order: "asc" } });
  } else if (sort === "is_novelties") {
    res.push(stock);
    res.push({ "search_data.is_novelties": { order: "asc" } });
    res.push(pupular);
  } else if (sort === "is_bestseller") {
    res.push(stock);
    res.push({ "search_data.is_bestseller": { order: "asc" } });
    res.push(pupular);
  }
  return res;
};

export const requestProductListTemplate = (props) => (options) => {
  props.setLoading ? props.setLoading(true) : null;
  options = options || {};
  const ecommerceStoreGroupId = props.ecommerceStoreGroupId || "";
  const ecommerceStoreId = props.ecommerceStoreId || "";
  const page = options.page || 1;
  const pageSize = props.pageSize || 24;
  const sort = options.sort;
  const facetSelectedDict = {};

  if (!props.noFacet) {
    Object.keys(options).forEach((key) => {
      if (key.endsWith("_filter")) {
        const values = options[key]
          .split(";")
          .filter((v) => !!v)
          .map((v) => ({ value: v }));
        if (values.length !== 0) {
          facetSelectedDict[key] = values;
        }
      }
    });
  }
  const query = {
    from: props.from || (page - 1 || 0) * pageSize,
    size: pageSize,
    sort: props.createSortQuery
      ? props.createSortQuery(sort, ecommerceStoreId, {
          isSearch: !!options.search,
        })
      : createSortQuery(sort, ecommerceStoreId, { isSearch: !!options.search }),
    _source: props.source || ["search_result_data"],
    query: {
      bool: {
        must: [],
        filter: [],
        must_not: [],
      },
    },
    ...(props.noFacet ? {} : buildFacetQuery(facetSelectedDict)),
  };

  if (ecommerceStoreGroupId) {
    query.query.bool.filter.push({
      term: { "search_data.ecommerce_store_group_id": ecommerceStoreGroupId },
    });
  }

  if (props.withUserAuth) {
    query.query.bool.filter.push("$authTerm");
  }

  if (props.getFilterQuery) {
    (props.getFilterQuery() || []).forEach((item, i) => {
      query.query.bool.filter.push(item);
    });
  }

  if (!props.showHidden) {
    query.query.bool.must_not.push({
      term: {
        "hidden.id_list": props.hiddenDirect ? "default" : ecommerceStoreId,
      },
    });
  }

  if (props.ecommerceStoreId) {
    query.query.bool.filter.push({
      term: { "search_data.ecommerce_store_id": ecommerceStoreId },
    });
  }
  const search = options.search || "";
  if (search) {
    const searchQuery = {
      multi_match: {
        query: search.toLowerCase().replace("%", ""),
        fields: ["search_data.full_text^5", "search_data.full_text.ngram"],
      },
    };
    query.query.bool.must.push(searchQuery);
    if (!props.noSuggest) {
      const suggest = {
        spelling: {
          text: search,
          term: {
            field: "search_data.full_text",
          },
        },
      };
      query["suggest"] = suggest;
    }
  }

  const category = options.category || "";
  if (category) {
    const categoryList = category.split(",").filter((v) => !!v);
    if (categoryList.length > 0) {
      query.query.bool.filter.push({
        terms: { "category.id_list": categoryList },
      });
    } else {
      query.query.bool.filter.push({ term: { "category.id_list": category } });
    }
  }

  if (ecommerceStoreGroupId && search && !props.searchInStoreGroup) {
    query.query.bool.filter.push({
      term: { "ecommerce_store.id_list": ecommerceStoreId },
    });
  }

  return dataSourceV2(
    `${props.path || "product_view_list"}?is_search=true&postprocess=${
      options.postprocess || props.postprocess || ecommerceStoreId
    }&query=${JSON.stringify(query)}`,
    { url: props.apiPath }
  ).then((v) => {
    if (!v.message && v.item_list) {
      const itemList = v.item_list.map((data) => {
        const category_id_dict = data.category_id_dict || {};
        const version = data.version || {};
        const res = {
          ...version,
          category_id_dict,
          attribute_set_id: data.attribute_set_id,
        };
        res[props.itemId || "product_id"] = data[props.itemId || "product_id"];

        return res;
      });
      const facetData = { facetDataDict: {}, facetDataList: [] };
      if (v.aggregations) {
        (
          (
            ((v.aggregations.general_facet || {}).agg_string_facet || {})
              .facet_name || {}
          ).buckets || []
        ).map((bucket, i) => {
          const key = bucket.key;
          const values = ((bucket.facet_value || {}).buckets || []).map(
            (value) => {
              const keySplit = value.key.split("|");
              return {
                ...value,
                key: keySplit[1],
                name: keySplit[1],
                value: keySplit[0],
              };
            }
          );
          facetData.facetDataDict[key] = values;
          facetData.facetDataList.push(key);
        });
        Object.keys(v.aggregations).forEach((aggKey) => {
          if (aggKey.startsWith("special_facet_")) {
            const key = aggKey
              .replace("special_facet_", "")
              .replace("_filter", "");
            const values = (
              (
                (
                  (v.aggregations[aggKey].agg_string_facet || {}).facet_name ||
                  {}
                ).facet_value || {}
              ).buckets || []
            ).map((value) => {
              const keySplit = value.key.split("|");
              return {
                ...value,
                key: keySplit[1],
                name: keySplit[1],
                value: keySplit[0],
              };
            });
            facetData.facetDataDict[key] = values;
          }
        });
      }
      props.setItemData
        ? props.setItemData({
            itemList,
            itemTotal: v.size || 0,
            pageTotal: Math.ceil((v.size || 0) / pageSize),
            searchSynonymList: [],
            prevParams: location.search,
          })
        : null;
      props.setFacetData ? props.setFacetData(facetData) : null;
    } else {
      const searchSynonymList = [];
      if (v.suggest) {
        ((v.suggest || {}).spelling || []).forEach((spell) => {
          (spell.options || []).forEach((option) => {
            searchSynonymList.push({ name: option.text || "" });
          });
        });
      }
      props.setItemData
        ? props.setItemData({
            itemList: [],
            pageTotal: 0,
            itemTotal: 0,
            searchSynonymList,
          })
        : null;
      props.setFacetData
        ? props.setFacetData({ facetDataDict: {}, facetDataList: [] })
        : null;
    }
    props.setLoading ? props.setLoading(false) : null;
    return v;
  });
};
