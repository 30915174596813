import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import "./style.styl";

export default function Notification(props) {
  const { show, message, dispatch } = props;

  const onClose = () => dispatch("notification", { show: false });

  return (
    <Snackbar
      className="notification-bar"
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={show}
      autoHideDuration={3000}
      onClose={onClose}
      ContentProps={{
        "aria-describedby": "message-id",
      }}
      message={<span id="message-id">{message}</span>}
    />
  );
}

export const notification = {
  message: "",
  show: false,
  type: "",
};
