import queryString from "query-string";

export const stripHTML = (s) => s ? s.replace(/<[^>]*>/g, "").replace(/\s+/, "").replace(/(\r\n|\n|\r)/gm, "")  : s;

export const camelToSnake = (v) => v.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);


export const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1);


export const cleanHTML = (obj) => obj ? obj.replace(new RegExp("<p>", "g"), "").replace(new RegExp("</p>", "g"), "") : "";

export const UUID = () => {
    var dt = new Date().getTime();
    var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
        var r = (dt + Math.random()*16)%16 | 0;
        dt = Math.floor(dt/16);
        return (c=="x" ? r :(r&0x3|0x8)).toString(16);
    });
    return uuid;
};


export const getFromLS = (key) => {
    let ls = undefined;
    if (global.localStorage) {
        try {
            ls = JSON.parse(global.localStorage.getItem(key));
        } catch (e) {
            /*Ignore*/
        }
    }
    return ls;
};

export const saveToLS = (key, value) => {
    if (global.localStorage) {
        global.localStorage.setItem(
            key,
            JSON.stringify(value)
        );
    }
};

export const  deleteFromLS = (key) => {
    if (global.localStorage) {
        global.localStorage.removeItem(key);
    }
};



export const addParamToQueryString = (key, value, location, options) => {
    options = options || {};
    const params = queryString.parse(location.search);
    params[key] = value;
    return (options.pathname || location.pathname) + "?" + queryString.stringify(params);
};

export const deleteParamFromQueryString = (key, location, options) => {
    options = options || {};
    const params = queryString.parse(location.search);
    delete params[key];
    return (options.pathname || location.pathname) + "?" + queryString.stringify(params);
};

export const changeParamsInQueryString = (items, options) => {
    options = options || {};
    const params = queryString.parse(location.search);
    items.forEach((item, i) => {
        if (item.value) {
            params[item.key] = item.value;
        } else {
            delete params[item.key];
        }
    });

    return (options.pathname || location.pathname) + "?" + queryString.stringify(params);
};
